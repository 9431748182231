import React, { useEffect, useState } from "react";
import ViewCongrats from "./approved-request";
import RejectRequest from "./reject-request";
import CustomLoader from "../../../common/CustomLoader";

const ApplicationResponse = () => {
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("QUOTE-RESPONSE")) {
      setInfo(JSON.parse(localStorage.getItem("QUOTE-RESPONSE") || "{}"));
    }
    setLoading(false);
  }, []);

  return (
    <div className="min-h-screen w-full p-4 flex justify-center items-center">
      <CustomLoader isLoading={loading} />
      {info?.montoAFinanciar ? <ViewCongrats /> : <RejectRequest />}{" "}
    </div>
  );
};

export default ApplicationResponse;
