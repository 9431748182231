/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import DocumentItemList from "./DocumentItemList";
import CustomModal from "../../common/CustomModal";
import CustomFileUploader from "./CustomFileUploader";
import Request, { BASE_URLS } from "../../utils/fetchUtils";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { fetchDataFoldersZoho } from "../../redux/actions/generalActions";
import CustomLoader from "../../common/CustomLoader";
import SpellingCorrection from "../../common/SpellingCorrection";
import { Card } from "@/components/ui/card";
import Modal from "@/components/modal";
import { Label } from "@radix-ui/react-label";
import he from "he";
import { axiosInstance } from "@/utils/axios-instance";
import { toast } from "sonner";

const mapTypes = {
  ultimos_6_estados_de_cuenta_de_arrendatario: "stateAccount",
  comprobante_de_domicilio_de_arrendatario: "proofAddress",
  acta_constitutiva_con_RPP: "acta",
  poder_notarial_del_RL: "representante",
  comprobante_de_domiclio_de_la_empresa: "proofAddress",
  ultimos_6_estados_de_cuenta_de_la_empresa: "stateAccount",
  id_oficial_de_RL_y_accionistas_mayoritarios: "ine",
};

const ItemFolderDocs = (props) => {
  const { folderData, gridView, setFolderSelected, sameAvalRL } = props;
  const [modalUploadDocs, setModalUploadDocs] = useState(false);
  const [modalUploadDocs2, setModalUploadDocs2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [estatusData, setEstatusData] = useState([]);
  const dispatch = useDispatch();

  const handleChange = () => {
    // console.log(uploadedFiles);
    processDocs(uploadedFiles);
  };

  let firstTwoDocs;

  if (folderData?.files && folderData?.files.length > 2) {
    firstTwoDocs = folderData.files.slice(0, 2);
  }

  useEffect(() => {
    setEstatusData(folderData);
    console.log("FOLDERDATA", folderData);
  }, [folderData]);

  function formatearNombre(nombre) {
    const palabras = nombre.split("_");

    const nombreFormateado = palabras
      .map((palabra) => palabra.charAt(0).toUpperCase() + palabra.slice(1))
      .join(" ");

    return nombreFormateado;
  }

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => resolve(e.target.result.split(",")[1]);
      reader.onerror = (err) => reject(err);

      reader.readAsDataURL(file);
    });
  };

  const processDocs = async (fileInput, idx) => {
    setLoading(true);
    try {
      if (fileInput.length > 0) {
        var getPresignedUrlBody = new Array();
        for (let i = 0; i < fileInput.length; i++) {
          const file = fileInput[i];
          const newFile = new File(
            [fileInput[i]],
            estatusData.Name + "." + file.type.split("/")[1],
            {
              type: fileInput[i].type,
            }
          );
          const { name, type } = newFile;
          getPresignedUrlBody.push({ name, type, userFileName: file.name });
        }
        // console.log("GETPRESIGNEDURLBODY", getPresignedUrlBody);
        // setLoading(false);
        // return;

        const presignedUrls = await fetchS3PresignedUrls({
          files: getPresignedUrlBody,
        });

        await Promise.all(
          presignedUrls.files.map(async (x, i) => {
            const { documentName, folderName } = x;

            if (
              documentName?.includes(".pdf") ||
              documentName?.includes(".png") ||
              documentName?.includes(".jpg") ||
              documentName?.includes(".jpeg")
            ) {
              const file = new File([fileInput[i]], documentName, {
                type: fileInput[i].type,
              });

              const type = mapTypes[folderName];

              const base64String = await convertFileToBase64(file);
              await axiosInstance.post("/admin/nufi-files", {
                fileBase64: base64String,
                documentType: type,
                userId: localStorage.getItem("zohoContactId"),
              });
            }
          })
        );

        const PromisesPromises = presignedUrls.files.map((x, i) => {
          const { s3SignedUrl, documentName, s3SignedFields } = x;

          const file = new File([fileInput[i]], documentName, {
            type: fileInput[i].type,
          });

          const response = s3PutObjectAction(s3SignedUrl, file, s3SignedFields);

          return response;
        });

        const PromiseAll = await Promise.all(PromisesPromises);

        const resultFailedAll = PromiseAll.filter((P) => !P.ok);
        let timerInterval;
        if (resultFailedAll.length === 0) {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "¡Se han subido sus documentos exitosamente!",
            text: "Revise el estatus en su dashboard para más seguimiento",
            html: "Verá reflejado sus cambios en <b></b> segundos.",
            timer: 5000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const timer = Swal.getPopup().querySelector("b");
              timerInterval = setInterval(() => {
                timer.textContent = `${Math.floor(Swal.getTimerLeft() / 1000)}`;
              }, 10);
            },
          }).then(
            () => (
              setModalUploadDocs(false),
              dispatch(fetchDataFoldersZoho(setLoading))
            )
          );
        }

        setLoading(false);
      }
    } catch (e) {
      toast.error(
        `Error al subir los documentos: ${
          e?.response?.data?.message ?? e.message
        }`
      );
      console.log(e);
      setLoading(false);
    }
  };

  const fetchS3PresignedUrls = async (files) => {
    const tokenJwt = localStorage.getItem("jwt");
    const res = await Request(
      BASE_URLS.GENERAL + "/staging/back-office/user/presigned-url-s3",
      files,
      "POST",
      false,
      false,
      {
        Authorization: `Bearer ${tokenJwt}`,
      }
    );
    return res;
  };

  const s3PutObjectAction = async (presignedUrl, file, s3SignedFields) => {
    try {
      const formData = new FormData();
      Object.keys(s3SignedFields).forEach((key) => {
        formData.append(key, s3SignedFields[key]);
      });
      formData.append("file", file);
      const res = await Request(presignedUrl, formData, "POST", true);
      console.log(res, "aqui se envia el put");
      return res;
    } catch (e) {
      console.log(e);
    }
  };

  const resetFolder = async (folderName, expendienteID) => {
    setLoading(true);
    const tokenJwt = localStorage.getItem("jwt");
    const res = await Request(
      BASE_URLS.GENERAL +
        `/staging/back-office/user/folder/reset-folder?id_folder=${expendienteID}&folderName=${folderName}`,
      {},
      "GET",
      false,
      false,
      {
        Authorization: `Bearer ${tokenJwt}`,
      }
    );
    if (!res.ok) {
      setLoading(false);
    }
    let timerInterval;
    if (res.status) {
      Swal.fire({
        icon: "success",
        title: "¡Puede volver a subir sus documentos!",
        text: "Revise el estatus en su dashboard para más seguimiento",
        html: "Verá reflejado sus cambios en <b></b> segundos.",
        timer: 5000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const timer = Swal.getPopup().querySelector("b");
          timerInterval = setInterval(() => {
            timer.textContent = `${Math.floor(Swal.getTimerLeft() / 1000)}`;
          }, 10);
        },
      }).then(() => setModalUploadDocs2(false));
    }
  };

  return (
    <>
      <CustomLoader isLoading={loading} />
      <Card
        className="cursor-pointer"
        onClick={
          sameAvalRL &&
          estatusData?.Carpeta_superior ===
            "Documentos de aval&#x2f;obligado solidario &#x28;OS&#x29;"
            ? null
            : () =>
                estatusData?.Estatus === null ||
                estatusData?.Estatus === "Pendiente"
                  ? setModalUploadDocs(true)
                  : setFolderSelected(estatusData)
        }
      >
        <div className="flex items-center justify-between px-4 gap-x-3 py-2">
          <div className="flex items-center gap-x-3">
            <div>
              {estatusData?.Estatus === "Pendiente" && (
                <img
                  className="iconItem h-10"
                  src="./images/nuevoIcon.svg"
                  alt=""
                ></img>
              )}
              {estatusData?.Estatus === "En revisión" && (
                <img
                  className="iconItem h-10"
                  src="./images/esperaIcon.svg"
                  alt=""
                ></img>
              )}
              {estatusData?.Estatus === "Aprobado" && (
                <img
                  className="iconItem h-10"
                  src="./images/aceptadoIcon.svg"
                  alt=""
                ></img>
              )}
              {estatusData?.Estatus === "Rechazado" && (
                <img
                  className="iconItem h-10"
                  src="./images/rechazadoIcon.svg"
                  alt=""
                ></img>
              )}
            </div>

            <Label className="text-sm font-semibold">
              <SpellingCorrection
                texto={he.decode(formatearNombre(folderData.Name))}
              />
            </Label>
          </div>
          {sameAvalRL &&
          estatusData?.Carpeta_superior ===
            "Documentos de aval&#x2f;obligado solidario &#x28;OS&#x29;" ? (
            <Label>Documentos iguales al Representante Legal</Label>
          ) : (
            <div>
              {estatusData?.Estatus === "Pendiente" && (
                <Label>Haga clic aquí para subir sus archivos...</Label>
              )}
              {estatusData?.Estatus !== "Pendiente" && (
                <Label>Mis documentos ({estatusData?.files?.length})</Label>
              )}
            </div>
          )}
        </div>
        {estatusData?.files?.length >= 1 && (
          <Label
            className="text-sm font-semibold text-primary text-center ml-3 underline cursor-pointer"
            onClick={() => setModalUploadDocs2(true)}
          >
            {estatusData?.Estatus === "Rechazado"
              ? "Ver motivo de rechazo"
              : "Ver todos los documentos"}
          </Label>
        )}
      </Card>
      <Modal open={modalUploadDocs} setOpen={setModalUploadDocs}>
        <Label className="text-black font-semibold text-center text-xl">
          Subir documentos
        </Label>
        <span>
          Si guardas los cambios tu archivo se subirá y pasará a revisión
        </span>
        <div className="modal-container">
          <div className="text-primary font-semibold mb-3">
            <SpellingCorrection texto={formatearNombre(folderData.Name)} />
          </div>
          <CustomFileUploader
            handleChange={handleChange}
            setUploadedFiles={setUploadedFiles}
            uploadedFiles={uploadedFiles}
          ></CustomFileUploader>
        </div>
      </Modal>
      <CustomModal
        open={modalUploadDocs2}
        setOpen={setModalUploadDocs2}
        title={"Mis Documentos"}
        withBtnsFooter={false}
        strongTitle={true}
      >
        <div className="modal-container">
          <Label>{formatearNombre(folderData.Name)}</Label>
          <Label className="text-left flex items-center justify-start gap-x-3 my-2 w-full">
            Total de documentos:{" "}
            <span className="font-semibold">{estatusData?.files?.length}</span>
          </Label>
          <Card className="w-full p-4">
            {estatusData?.files &&
              estatusData?.files?.map((x) => (
                <DocumentItemList
                  docsList={x}
                  key={x.id}
                  docsStatus={estatusData?.Estatus}
                ></DocumentItemList>
              ))}
          </Card>
          {estatusData?.Estatus === "Rechazado" && (
            <>
              <div
                style={{
                  width: "100%",
                  fontSize: "1vw",
                  fontFamily: "Guaruja Neue Regular",
                }}
              >
                Motivo del rechazo:
              </div>
              <div className="seccionDocumentos-item list modal">
                <div
                  style={{
                    color: "black",
                    width: "100%",
                    fontFamily: "Guaruja Neue Regular",
                    fontStyle: "italic",
                    maxHeight: "12vw",
                    overflow: "auto",
                  }}
                >
                  {estatusData?.Comentarios}
                </div>
              </div>
              <div
                className="btnVolerPendiente"
                onClick={() =>
                  resetFolder(estatusData.Name, estatusData.expedienteId)
                }
              >
                Acepto el motivo y deseo intentarlo otra vez
              </div>
            </>
          )}
        </div>
      </CustomModal>
    </>
  );
};

ItemFolderDocs.propTypes = {};

export default ItemFolderDocs;
