import axios from "axios";
import PropTypes from "prop-types";

export const BASE_URLS = {
  GENERAL: "https://f101pahekh.execute-api.us-east-2.amazonaws.com",
};

const Request = async (
  path,
  body = {},
  method = "GET",
  isFormdata = false,
  isResponseBlob = false,
  headersProp
) => {
  const headers = headersProp || {
    ...(isFormdata
      ? { "Content-Type": "multipart/form-data" }
      : {
          "Content-Type": "application/json",
        }),
  };

  try {
    const response = await axios({
      url: path,
      method,
      headers,
      data: ["GET", "DELETE"].includes(method)
        ? null
        : isFormdata
        ? body
        : body,
    });

    if (
      (response.status === 204 || response.status === 200) &&
      (method === "PATCH" || response.statusText === "No Content")
    )
      return { ok: true };

    return response?.data;
  } catch (error) {
    return {
      status: 500,
      message:
        error?.response?.data?.message ||
        error.message ||
        "Something went wrong",
    };
  }
};

Request.propTypes = {
  path: PropTypes.string.isRequired,
  body: PropTypes.object,
  method: PropTypes.oneOf(["GET", "POST", "PUT", "PATCH", "DELETE"]),
  isFormdata: PropTypes.bool,
  isResponseBlob: PropTypes.bool,
};

export default Request;
