import { Grid, List } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLoader from "@/common/CustomLoader";
import MainLayout from "@/components/layout/AdminLayout";
import { fetchDataFoldersZoho } from "@/redux/actions/generalActions";
import CarruselInfo from "@/modules/PortalCargaDocumentos/CarruselInfo";
import ProgressDocBar from "@/modules/PortalCargaDocumentos/ProgressDocBar";
import SeccionDocumentos from "@/modules/PortalCargaDocumentos/SeccionDocumentos";
import { Label } from "@/components/ui/label";
import { Card } from "@/components/ui/card";
import Modal from "@/components/modal";
import { useSearchParams } from "react-router-dom";

const DocumentUpload = () => {
  const [isFilterGrid, setIsFilterGrid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [arreglosAgrupados, setArreglosAgrupados] = useState([]);

  const [folderSelected, setFolderSelected] = useState({});
  const [checked, setChecked] = useState(
    localStorage.getItem("avalMismoRL") === "Si" ? true : false
  );

  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.general);

  function formatearNombre(nombre) {
    const palabras = nombre.split("_");
    const nombreFormateado = palabras
      .map((palabra) => palabra.charAt(0).toUpperCase() + palabra.slice(1))
      .join(" ");

    return nombreFormateado;
  }

  const paragraphs = [
    "Arrastra tus documentos en los siguientes modulos o pulsa en “buscar en mi pc” para buscarlos en tu ordenador. Completa los siguientes pasos para comenzar tu arrendamiento con Finerik.",
    "Puedes acelerar tu proceso de aprobación, adelántate subiendo tus documentos si ya los tienes a la mano.",
    "¿Tienes alguna duda? No dudes en enviarnos un mensaje si te has perdido.",
    "¿Ya viste los barras? 👀 estos te ayudan a medir tu progreso de una manera rápida, continua con la carga de tus documentos.",
    "Haz clic en los siguientes modulos para abrir una ventana emergente y buscar los archivos en tu ordenador. Completa los siguientes pasos para comenzar tu arrendamiento con Finerik.",
  ];

  useEffect(() => {
    dispatch(fetchDataFoldersZoho(setLoading, setArreglosAgrupados));
  }, [dispatch, userData]);

  const obtenerCantidadAprobados = (arreglo) => {
    return arreglo.filter((objeto) => objeto.Estatus === "Aprobado").length;
  };

  const TituloCargaDocumental =
    (localStorage.getItem("layout") === "Persona Fisica"
      ? "PFAE"
      : localStorage.getItem("layout")) +
    " con " +
    localStorage.getItem("tipoServicio");

  return (
    <>
      <CustomLoader isLoading={loading} />
      <div className="py-4 grid grid-cols-12">
        <div className="col-span-12 lg:col-span-9 lg:px-3">
          <div>
            <CarruselInfo paragraphs={paragraphs} />
          </div>
          <div className="flex justify-between items-center my-2">
            <Label className="text-xl lg:text-4xl">
              Carga de documentos -{" "}
              <Label className="!text-lg lg:!text-3xl">
                {TituloCargaDocumental}
              </Label>
            </Label>
          </div>
          {arreglosAgrupados?.map((x) => (
            <SeccionDocumentos
              data={x}
              gridView={isFilterGrid}
              key={x.id}
              setFolderSelected={setFolderSelected}
              checked={checked}
              setChecked={setChecked}
            />
          ))}
        </div>
        <div className="col-span-12 lg:col-span-3">
          <div className="mb-4">
            <Label className="text-xl lg:text-2xl">Documentos Aprobados</Label>
            <div>
              {arreglosAgrupados?.map((x) => (
                <ProgressDocBar
                  catName={x[0].Carpeta_superior}
                  key={x[0].Carpeta_superior}
                  percent={obtenerCantidadAprobados(x)}
                  arrayLength={x.length}
                ></ProgressDocBar>
              ))}
            </div>
          </div>
          <Card className="min-h-64 bg-sidebar-accent flex flex-col justify-center items-center p-2">
            <>
              <div
                className="item-icon-grid"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {folderSelected?.Estatus === null && (
                  <img
                    className="h-10"
                    src="./images/nuevoIcon.svg"
                    alt=""
                  ></img>
                )}
                {folderSelected?.Estatus === "Pendiente" && (
                  <img
                    className="h-10"
                    src="./images/nuevoIcon.svg"
                    alt=""
                  ></img>
                )}
                {folderSelected?.Estatus === "En revisión" && (
                  <img
                    className="h-10"
                    src="./images/esperaIcon.svg"
                    alt=""
                  ></img>
                )}
                {folderSelected?.Estatus === "Aprobado" && (
                  <img
                    className="h-10"
                    src="./images/aceptadoIcon.svg"
                    alt=""
                  ></img>
                )}
                {folderSelected?.Estatus === "Rechazado" && (
                  <img
                    className="h-10"
                    src="./images/rechazadoIcon.svg"
                    alt=""
                  ></img>
                )}
              </div>
              <Label
                className={`w-full text-center font-semibold text-lg ${
                  folderSelected?.Estatus === "En revisión"
                    ? "revision"
                    : folderSelected?.Estatus === "Rechazado"
                    ? "rechazado"
                    : folderSelected?.Estatus === "Aprobado"
                    ? "aprobado"
                    : ""
                }`}
              >
                {folderSelected?.Estatus === "En revisión"
                  ? "Documentos en revisión"
                  : folderSelected?.Estatus === "Rechazado"
                  ? "Uno o más documentos no han sido aprobados"
                  : folderSelected?.Estatus === "Aprobado"
                  ? "Documentos aprobados"
                  : ""}
              </Label>
              {folderSelected?.Estatus && (
                <Label
                  className={`w-full text-center text-sm ${
                    folderSelected?.Estatus === "En revisión"
                      ? "revision"
                      : folderSelected?.Estatus === "Rechazado"
                      ? "rechazado"
                      : folderSelected?.Estatus === "Aprobado"
                      ? "aprobado"
                      : ""
                  }`}
                >
                  {formatearNombre(folderSelected?.Name)}
                </Label>
              )}

              <Label className="text-sm">
                {folderSelected?.Estatus === "En revisión"
                  ? "Estamos revisando tus archivos para asegurarnos de que estén listos. Te avisaremos pronto."
                  : folderSelected?.Estatus === "Rechazado"
                  ? "¡Ups! Alguno de tus documentos no fue aprobado. Por favor, revisa los detalles y asegúrate de que estén en orden."
                  : folderSelected?.Estatus === "Aprobado"
                  ? "¡Felicidades! todos tus documentos han sido aprobados."
                  : ""}
              </Label>
            </>
            {folderSelected?.Estatus && (
              <div className="border border-gray-800 rounded-lg p-1 my-1">
                <img
                  className="iconItem"
                  src="./images/moreInfoIcon.svg"
                  alt=""
                  style={{ marginBottom: "0.3vw" }}
                ></img>
                <Label className="text-xs text-center">
                  {folderSelected?.Estatus === "En revisión"
                    ? "Por favor ten en cuenta que el proceso de revisión puede tardar de 3 a 5 días hábiles"
                    : folderSelected?.Estatus === "Rechazado"
                    ? "Revisa que tus documentos estén correctamente escaneados y en el orden correcto."
                    : folderSelected?.Estatus === "Aprobado"
                    ? "Recuerda que la vigencia de tus documentos aprobados es de 3 meses. ¡No olvides completar tu proceso a tiempo!"
                    : ""}
                </Label>
              </div>
            )}
          </Card>
        </div>
      </div>
    </>
  );
};

const Root = () => {
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("welcome")) setOpen(true);
  }, []);

  return (
    <MainLayout>
      <Modal open={open} title="Video tutorial" setOpen={setOpen}>
        <div className="py-8">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/pgHqF5s0tgA?autoplay=1&si=ZJ35Ikrk6STbs6Qx"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </Modal>
      <DocumentUpload />
    </MainLayout>
  );
};

export default Root;
