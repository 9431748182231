import { Button } from "@/components/ui/button";
import React, { useState, useEffect } from "react";

const CookieConsentModal = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (!cookiesAccepted) {
      setShowModal(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setShowModal(false);
  };

  return (
    showModal && (
      <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white px-4 py-3 shadow-lg z-50">
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
          <p className="text-sm">
            Utilizamos cookies para mejorar tu experiencia. Al continuar
            navegando, aceptas nuestra{" "}
            <a
              href="https://www.finerik.com/aviso-de-privacidad"
              className="underline text-blue-400 hover:text-blue-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              Política de Cookies
            </a>
            .
          </p>
          <Button onClick={handleAccept}>Aceptar</Button>
        </div>
      </div>
    )
  );
};

export default CookieConsentModal;
