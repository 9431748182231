import "./App.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import OnBoardingV1 from "./pages/on-boarding/quote";
import CargaDocumental from "./pages/admin/carga-documental";
import Dashboard from "./pages/admin/dashboard";
import HistorialSolicitudes from "./pages/admin/historial-solicitudes";
import { Provider } from "react-redux";
import { store } from "./redux/store";

import Login from "./pages/auth/login";
import ApplicationResponse from "./pages/on-boarding/response-request";
import ViewLeasing from "./pages/assets/leasing";
import Forgot from "./pages/auth/forgot";
import { Toaster } from "./components/ui/sonner";

const App = () => {
  return (
    <Provider store={store}>
      <AppRouter />
      <Toaster position="top-right" />
    </Provider>
  );
};

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to={"/auth/login"} />} />
        <Route path="*" element={<Navigate to={"/auth/login"} />} />
        <Route exact path="/on-boarding" element={<OnBoardingV1 />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/carga-documental" element={<CargaDocumental />} />
        <Route
          exact
          path="/historial-solicitudes"
          element={<HistorialSolicitudes />}
        />
        <Route
          exact
          path="/application-response"
          element={<ApplicationResponse />}
        />
        <Route exact path="/auth/login" element={<Login />} />
        <Route exact path="/auth/forgot" element={<Forgot />} />
        <Route exact path="/leasing" element={<ViewLeasing />} />
      </Routes>
    </Router>
  );
};

export default App;
