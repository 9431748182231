import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import SuccessIcon from "@/assets/icons/aceptado.png";
import { HelpCircle } from "lucide-react";
import { Tooltip } from "react-tooltip";
import { robotoBase64, robotoBase64Bold } from "@/assets/index";
import jsPDF from "jspdf";

const ViewCongrats = () => {
  const navigate = useNavigate();
  const [quotationInfo, setQuotationInfo] = useState({});

  useEffect(() => {
    if (localStorage.getItem("QUOTE-RESPONSE")) {
      setQuotationInfo(
        JSON.parse(localStorage.getItem("QUOTE-RESPONSE") || "{}")
      );
      return;
    }
  }, []);

  const generatePdfImage = async (imageUrl = "") => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      const base64data = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });

      return base64data;
    } catch (error) {
      console.error("Error cargando la imagen:", error);
      return "";
    }
  };

  const generatePDF = async (quotationInfo, preview) => {
    const doc = new jsPDF();
    doc.setFontSize(8);
    doc.text("finerik.com", 5, 10);

    doc.addFileToVFS("Roboto.ttf", robotoBase64);
    doc.addFont("Roboto.ttf", "Roboto", "normal");
    doc.addFileToVFS("Roboto-bold.ttf", robotoBase64Bold);
    doc.addFont("Roboto-bold.ttf", "RobotoBold", "bold");

    doc.setFont("Roboto");
    let imgData = await generatePdfImage(
      "https://finerikpublic.s3.us-east-2.amazonaws.com/finerik-logo.png"
    );

    doc.addImage(imgData, "PNG", 40, 10, 120, 50);

    doc.setFontSize(40);
    doc.setFont("RobotoBold", "bold");
    doc.text("Cotización Preliminar", 35, 60);

    doc.setFontSize(15);
    doc.setFont("Roboto", "normal");
    doc.text(
      "¡Bienvenido! Te compartimos un aproximado basado en las respuestas",
      15,
      70
    );
    doc.text(
      "de tu solicitud. Una vez que termines los siguientes pasos, trabajaremos",
      15,
      75
    );
    doc.text(
      "para que conozcas la propuesta ajustada a los números de tu negocio.",
      15,
      80
    );

    imgData = await generatePdfImage(
      "https://finerikpublic.s3.us-east-2.amazonaws.com/money_16812149.png"
    );
    doc.addImage(imgData, "PNG", 27, 90, 38, 38);

    doc.setFontSize(25);
    doc.setFont("RobotoBold", "bold");
    doc.text(
      "$" + parseInt(quotationInfo.montoAFinanciar, 10).toLocaleString("en"),
      85,
      120
    );
    doc.setFont("Roboto", "normal");
    doc.text("Monto a Financiar", 70, 110);

    doc.setFontSize(20);
    const docHeight = doc.internal.pageSize.getHeight();
    doc.line(50, 130, 150, 130);

    doc.text(`Plazos disponibles`, 35, 160);
    doc.text(`${quotationInfo.plazosDisponibles}`, 50, 170);
    imgData = await generatePdfImage(
      "https://finerikpublic.s3.us-east-2.amazonaws.com/wall-clock_18200906.png"
    );
    doc.addImage(imgData, "PNG", 2, 140, 35, 35);

    doc.text("Valor de compra final", 125, 160);
    doc.text(`${quotationInfo.valorDeCompraFinal}`, 135, 170);
    imgData = await generatePdfImage(
      "https://finerikpublic.s3.us-east-2.amazonaws.com/receipt_17001231.png"
    );
    doc.addImage(imgData, "PNG", 95, 140, 38, 38);

    doc.setFont("RobotoBold", "bold");
    doc.text("Posibles pagos mensuales de", 60, 210);
    imgData = await generatePdfImage(
      "https://finerikpublic.s3.us-east-2.amazonaws.com/payment_18564193.png"
    );
    doc.addImage(imgData, "PNG", 22, 190, 38, 35);

    doc.setFont("Roboto", "normal");
    doc.text(`Pago mínimo`, 35, 230);
    doc.text(`Pago máximo`, 135, 230);
    doc.text(`${quotationInfo.pagoMinimo}`, 45, 240);
    doc.text(`${quotationInfo.pagoMaximo}`, 145, 240);

    doc.setFontSize(8);
    doc.setFont("Roboto", "normal");
    doc.text(`Aplican terminos y condiciones`, 5, docHeight - 15);
    doc.text(
      `Esto no es una cotización finales. Los valores reales varian`,
      5,
      docHeight - 10
    );
    if (preview) {
      // frame.src = doc.output("bloburl");
      return;
    }
    doc.save(`Cotización Preliminar - Finerik`);
  };

  return (
    <Card>
      <div className="p-4 flex flex-col justify-center items-center">
        <img
          className="h-28 my-2"
          src={SuccessIcon}
          alt="congradulation icon"
        ></img>
        <Label className="text-center font-bold text-4xl my-2 mt-4">
          ¡Felicidades!
          <br /> Has sido pre-aprobado
        </Label>
        {/* <Label className="text-center">
          Hemos preparado una pre-cotización para tí, pulsa el siguiente botón
          para descargarla
        </Label> */}
        <div className="p-4 w-full">
          <Label className="text-center text-2xl font-semibold">
            Vista previa
          </Label>
          <div className="my-2">
            ${parseInt(quotationInfo.montoAFinanciar, 10).toLocaleString("en")}
          </div>
          <div className="mb-2">
            <Label className="text-lg text-center font-semibold">
              Monto a financiar
            </Label>
          </div>
          <div className="">
            <div className="flex items-center justify-between mb-1">
              <div className="flex items-center gap-x-1">
                <Label>Rango de rentas</Label>
                <HelpCircle
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Rango de rentas: Las rentas posibles que puede tener el cliente dependiendo del plazo y tasa."
                  className="help-icon !my-0"
                  fill={"#1A222F"}
                  color="white"
                ></HelpCircle>
                <Tooltip
                  id="my-tooltip"
                  style={{
                    whiteSpace: "break-spaces",
                    backgroundColor: "#1A222F",
                    fontWeight: 300,
                    fontSize: "1vw",
                    width: "20vw",
                    padding: "2vw",
                  }}
                  place="right"
                />
              </div>
              <Label className="font-medium">
                {quotationInfo.rangoDeRentas}
              </Label>
            </div>
            <div className="flex items-center justify-between mb-1">
              <div className="flex items-center gap-x-1">
                <Label>Plazos disponibles</Label>
                <HelpCircle
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Plazos disponibles: Los posibles plazos dependiendo del activo que eligió."
                  className="help-icon !my-0"
                  fill={"#1A222F"}
                  color="white"
                ></HelpCircle>
                <Tooltip
                  id="my-tooltip"
                  style={{
                    whiteSpace: "break-spaces",
                    backgroundColor: "#1A222F",
                    fontWeight: 300,
                    fontSize: "1vw",
                    width: "20vw",
                    padding: "2vw",
                  }}
                  place="right"
                />
              </div>

              <Label className="font-medium">
                {quotationInfo.plazosDisponibles}
              </Label>
            </div>
            <div className="flex items-center justify-between mb-1">
              <div className="flex items-center gap-x-1">
                <Label>Valor de compra final</Label>
                <HelpCircle
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Valor de compra final: El pago final necesario si deseas ser dueño del activo."
                  className="help-icon !my-0"
                  fill={"#1A222F"}
                  color="white"
                ></HelpCircle>
                <Tooltip
                  id="my-tooltip"
                  style={{
                    whiteSpace: "break-spaces",
                    backgroundColor: "#1A222F",
                    fontWeight: 300,
                    fontSize: "1vw",
                    width: "20vw",
                    padding: "2vw",
                  }}
                  place="right"
                />
              </div>
              <Label className="font-medium">
                {quotationInfo.valorDeCompraFinal}
              </Label>
            </div>
          </div>
          <div className="my-2">
            <Label className="text-center font-semibold text-lg">
              Posibles pagos mensuales de
            </Label>
          </div>
          <div className="my-2">
            <div className="my-2 flex gap-x-3">
              <Label>Pago mínimo</Label>
              <Label className="font-medium">{quotationInfo.pagoMinimo}</Label>
            </div>
            <div className="my-2 flex gap-x-3">
              <Label>Pago máximo</Label>
              <Label className="font-medium">{quotationInfo.pagoMaximo}</Label>
            </div>
          </div>
        </div>
        <div className="flex my-8 gap-x-4 items-center justify-center gap-y-4">
          <Button
            variant="secondary"
            onClick={() => generatePDF(quotationInfo, false)}
          >
            Descargar
          </Button>
          <Button
            onClick={() => {
              navigate("/carga-documental?welcome=1");
            }}
          >
            Continuar
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default ViewCongrats;
